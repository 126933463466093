<template>
    <div>
        <div id="mainWrapper" class="POSWrapper">
            <Modal :isVisible="showModalTip" @close="showModalTip = false">
                <h2>Add tip address and amount</h2>
                <input v-model="tipAddress" class="inputThing3 d-flex justify-content-center align-items-center"
                    placeholder="Tip lighting address">
                <div class="multipleInputContainer">
                    <input v-model="tipAmount" class="inputThing3Half d-flex justify-content-center align-items-center"
                        placeholder="Tip amount">
                    <select v-model="tipCurrency" class="inputThing2 d-flex justify-content-center align-items-center">
                        <option default :value="fiat">{{ fiat }}</option>
                        <option value="SATS">SATS</option>
                        <option value="Percent">%</option>
                    </select>
                </div>
                <div class="d-flex gap-4">
                    <button class="btn btn-secondary btn-secondary2" @click="showModalTip = false">Close</button>
                    <button class="btn btn-secondary-alt" @click="showModalTip = false">Add</button>
                </div>
            </Modal>

            <Modal :isVisible="showModalOrder" @close="showModalOrder = false">
                <h2>Add OrderID</h2>
                <h5>Enter orderID in order to track <br> invoice easier with Coinsnap</h5>

                <input v-model="orderId" class="inputThing3 d-flex justify-content-center align-items-center"
                    placeholder="OrderID">
                <div class="d-flex gap-4">
                    <button class="btn btn-secondary btn-secondary2"
                        @click="() => { showModalOrder = false; orderId = '' }">Close</button>
                    <button class="btn btn-secondary-alt" @click="showModalOrder = false">Add</button>
                </div>
            </Modal>

            <div class="topItems">
                <!-- <button class="btn btn-primary topbtn" @click="showModal = true"> -->
                <div>
                    <button class="btn btn-primary topbtn" :class="{ 'topbtnSelected': showDropdown }"
                        @click="showDropdown = showDropdown ? false : true">
                        <i class="fas fa-plus-circle"></i>
                        Add
                    </button>
                    <transition name="fade">

                        <div v-if="showDropdown" class="drop">
                            <!-- <div @click="() => { this.showDropdown = false; this.showModalTip = true }" class="dropItem">
                            Tip
                        </div> -->
                            <div @click="() => { this.showDropdown = false; this.showModalOrder = true }"
                                class="dropItem">
                                OrderID</div>
                        </div>
                    </transition>

                </div>
                <!-- <button @click="swapCurrency" class="btn btn-primary topbtn">
                    <i class="fas fa-exchange-alt"></i>
                    {{ this.currency == this.fiat ? "SATS" : this.fiat }}
                </button> -->
            </div>

            <div class="logoArea">
                <img src="../assets/logo.png" />
            </div>
            <div class="title">
                <h3 class="fw-bold posData">{{ data.name }}</h3>
                <!-- <h6 class="fw-bold posData">{{ title }}</h6> -->

            </div>
            <div class="inputThing">
                <div>
                    {{ inputVal }} {{ currency }}
                </div>
                <button @click="deleteNum" class="deleteLastNum">
                    <i class="fas fa-backspace"></i>
                </button>
            </div>
            <!-- <button @click="swapCurrency" class="btn btn-primary changeBtn">
                Change currency
                <i class="fas fa-exchange-alt"></i>
            </button> -->
            <div class="altCurrency d-flex justify-content-center align-items-center">
                {{ convertedValue }} {{ currency ==
                'SATS' ?
                this.data.settings?.currency : 'SATS' }}
                <button @click="swapCurrency" class="exchangeIcon">
                    <i class="fas fa-exchange-alt"></i>
                </button>
            </div>
            <!-- <input v-model="orderId" class="inputThing3 d-flex justify-content-center align-items-center"
            placeholder="OrderID"> -->
            <!-- <div class="inputThing2 d-flex justify-content-center align-items-center">
            {{ currency }}
        </div> -->
            <!-- <select v-model="currency" class="inputThing2 d-flex justify-content-center align-items-center">
            <option default :value="fiat">{{ fiat }}</option>
            <option value="SATS">SATS</option>
        </select> -->

            <div class="numbers container">
                <div class="row d-flex">
                    <div @click="addNum(1)" class="col number">1</div>
                    <div @click="addNum(2)" class="col number">2</div>
                    <div @click="addNum(3)" class="col number">3</div>
                </div>
                <div class="row d-flex">
                    <div @click="addNum(4)" class="col number">4</div>
                    <div @click="addNum(5)" class="col number">5</div>
                    <div @click="addNum(6)" class="col number">6</div>
                </div>
                <div class="row d-flex">
                    <div @click="addNum(7)" class="col number">7</div>
                    <div @click="addNum(8)" class="col number">8</div>
                    <div @click="addNum(9)" class="col number">9</div>
                </div>
                <div class="row d-flex">
                    <div @click="addNum(this.decSep)" class="col number">{{ decSep }}</div>
                    <div @click="addNum(0)" class="col number">0</div>
                    <div @click="clearInput" class="col number cNum">C</div>
                </div>
            </div>
            <div>
                <button @click="getQR" class="btn btn-secondary">Charge</button>
            </div>

            <div class="logoAreaUnder">
                <img src="../assets/pbc.png" />
            </div>
        </div>
    </div>
</template>

<script>
import Modal from './Modal.vue';

export default {
    name: "POS",
    components: {
        Modal
    },
    data() {
        return {
            decSep: ",",
            thousSep: ".",
            inputVal: ``,
            cleanVal: "0",
            currency: 'EUR',
            fiat: 'EUR',
            data: {},
            orderId: "",
            title: "",
            rate: 1,
            showModalTip: false,
            showModalOrder: false,
            tipCurrency: 'EUR',
            showDropdown: false,
            tipAddress: "",
            tipAmount: "",
            storeId: "",
            convertedValue: 0
        }
    },
    watch: {
        inputVal(newVal) {
            if (newVal[newVal.length - 1] != this.decSep) {
                this.convertedValue = this.currency == 'SATS' ? (this.cleanVal * this.rate).toFixed(8) : Math.ceil(this.cleanVal * 1 / this.rate)
                this.convertedValue = this.convertedValue?.toString().replaceAll(".", ",")
                this.convertedValue = this.formatNum(this.convertedValue)
            }
        }
    },
    methods: {
        swapCurrency() {
            if (this.currency == this.fiat) {
                this.currency = "SATS"
                this.inputVal = this.inputVal.toString().replaceAll(this.decSep, "")
                if (this.inputVal == "000") {
                    this.inputVal = `0${this.decSep}00`
                }
            } else {
                this.currency = this.fiat
            }
            this.cleanVal = this.decSep == "." ? this.inputVal : this.inputVal.replace(",", ".")
            this.convertedValue = this.currency == 'SATS' ? (this.cleanVal * this.rate).toFixed(8) : Math.ceil(this.cleanVal * 1 / this.rate)
                this.convertedValue = this.convertedValue?.toString().replaceAll(".", ",")
                this.convertedValue = this.formatNum(this.convertedValue)
            const oldVal = this.inputVal
            this.inputVal = `${this.inputVal}0`
            this.inputVal = oldVal
            // this.convertedValue = this.currency == 'SATS' ? (this.cleanVal * this.rate).toFixed(8) : Math.ceil(this.cleanVal * 1 / this.rate)
            // this.convertedValue = this.convertedValue?.toString().replaceAll(".", ",")
            // this.convertedValue = this.formatNum(this.convertedValue)
            // console.log("c1", this.convertedValue)

        },
        formatNum(num) {
            num = num.toString()?.replaceAll(this.thousSep, "")
            this.cleanVal = this.decSep == "." ? num : num?.toString().replace(",", ".")
            const numberSections = num?.split(this.decSep)
            const mainNumber = numberSections[0]
            const decimalPart = numberSections[1]
            const formattedNumber = parseInt(mainNumber, 10).toLocaleString("en-DE");
            return `${formattedNumber}${decimalPart ? `${this.decSep}${decimalPart}` : ''}`
        },
        addNum(num) {
            if (this.inputVal === `0${this.decSep}00`) {
                if (num == this.decSep) {
                    this.inputVal = `0${num}`;
                } else {
                    this.inputVal = num.toString();
                }
                this.cleanVal = this.decSep == "." ? this.inputVal : this.inputVal.replace(",", ".")
            } else {
                if (num === this.decSep && this.inputVal.includes(this.decSep)) {
                    console.log(this.inputVal)
                    return;
                }
                if (num === this.decSep && this.currency == "SATS") {
                    return;
                }
                if (this.inputVal.length > 9) {
                    return
                }
                if (num != this.decSep) {
                    this.inputVal += num.toString();
                    this.inputVal = this.formatNum(this.inputVal)
                } else {
                    this.inputVal += num.toString();
                }
            }
        },
        clearInput() {
            this.inputVal = `0${this.decSep}00`;
            this.cleanVal = '0'
        },
        deleteNum() {
            if (this.inputVal != `0${this.decSep}00`) {
                this.inputVal = this.inputVal?.slice(0, -1)
                this.inputVal = this.formatNum(this.inputVal)
            }

            if (this.inputVal == "NaN") {
                this.inputVal = `0${this.decSep}00`
                this.cleanVal = this.decSep == "." ? this.inputVal : this.inputVal.replace(",", ".")
            }
        },
        getQR() {
            this.cleanVal = this.decSep == "." ? this.inputVal : this.inputVal.replace(",", ".")
            let input = parseFloat(this.cleanVal)
            if (this.storeId == '') return
            this.$store.dispatch('postApiCallAction', {
                'hook': `/v1/stores/${this.storeId}/invoices`,
                'body': {
                    'amount': input,
                    'currency': this.currency,
                    'orderId': this.orderId,
                    'redirectUrl': window.location.href,
                    'referralCode': 'D17834',
                },
                'json': true,
            }).then(res => {
                if (!res?.checkoutLink) return
                window.location.href = res?.checkoutLink
            });
        },
        getPosID() {
            const urlParams = new URLSearchParams(window.location.search);
            try {
                this.posId = urlParams.get('data');
            }
            catch (e) {
                console.log(e)
                window.location.replace("https://coinsnap.io");
            }
        }

    },
    mounted() {
        this.getPosID()
        if (this.posId) {
            this.$store.dispatch('getApiCallAction', {
                'hook': `/apps/pos/${this.posId}`,
                json: false
            }).then(res => {
                if (res.status != 200) {
                    window.location.replace("https://coinsnap.io");
                }
                res.json().then(res => {
                    this.data = res
                    const decodedName = document.createElement('textarea');
                    const decodedTitle = document.createElement('textarea');

                    decodedName.innerHTML = res.name
                    decodedTitle.innerHTML = res.settings?.title;

                    this.data.name = decodedName.value
                    this.currency = res.settings?.currency
                    this.fiat = this.currency
                    this.title = decodedTitle.value
                    this.storeId = res.storeId
                    this.$store.dispatch('getApiCallAction', {
                        'hook': `/internal/stores/${this.storeId}/rates`,
                    }).then(res => {
                        if (res && Array.isArray(res)) {
                            res.forEach((rate) => {
                                if (rate.currencyPair == `SATS_${this.currency}`) {
                                    this.rate = rate.rate
                                }
                            })
                        } else {
                            this.rate = 0.2123
                        }
                    })

                })
            })
        }
        this.inputVal = `0${this.decSep}00`
    }
}
</script>

<style scoped>
.POSWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 400px;
    background-color: whitesmoke;
    border-radius: 20px;
    border: 1px solid rgba(142, 142, 142, 0.131);
    padding: 30px 0px 30px 0px;
    gap: 12px;
}

.topItems {
    display: flex;
    width: 85%;
    justify-content: space-between;
}

.topbtn {
    background-color: #f2f2f2 !important;
    color: #333333;
    font-weight: 500;
    border-color: #3333332d;
    padding: 1px 18px;
    border-radius: 16px;
}

.topbtn:hover {
    border-color: #3333332d;
    background-color: #FF9900 !important;
    color: white;
}

.changeBtn {
    background-color: #f2f2f2 !important;
    color: #333333;
    font-weight: 500;
    border-color: #3333332d;
    padding: 1px 18px;
    border-radius: 8px;
}

.changeBtn:hover {
    border-color: #3333332d;
    background-color: #466a92 !important;
    color: white;
}


.topbtnSelected {
    border-radius: 12px 12px 0px 0px;
    width: 110px;
    transition: 0.3s;
}

.multipleInputContainer {
    width: 400px;
    display: flex;
}

.drop {
    transition: 0.3s;
    position: absolute;
    margin-left: px;
    border: 1px solid #3333332d;
    border-radius: 0px 0px 12px 12px;
    width: 110px;
    background-color: #f2f2f2;
}

.fade-enter-active {
    transition: all 0.3s ease-out;
}

.fade-leave-active {
    transition: all 0.3s ease-in;
}

.fade-enter-from {
    transform: translateY(-8px);
    opacity: 0;

}

.fade-leave-to {
    transform: translateY(20px);
    opacity: 0;
}


.drop>*:not(:last-child) {
    border-bottom: 1px solid #3333339b;
}

.drop>*:last-child {
    border-radius: 0px 0px 12px 12px;
}


.dropItem {
    padding: 5px 0px;
}


.dropItem:hover {
    background-color: rgb(225, 225, 225);
    cursor: pointer;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.logo {
    height: 100px;
}

.logoArea {
    height: 60px;
}

.logoAreaUnder {
    margin-top: -10px;
    height: 30px
}

.inputThing {
    height: 50px;
    width: 360px;
    color: #333333;
    margin: 0px 20px 0px 20px;
    border: 1.8px solid rgba(0, 0, 0, 0.185);
    border-radius: 10px;
    font-size: 32px;
    font-weight: bold;
}

.altCurrency {
    height: 35px;
    width: 360px;
    color: #5b5b5b;
    margin: 0px 20px 0px 20px;
    border: 1px solid rgba(0, 0, 0, 0.145);
    border-radius: 10px;
    font-size: 22px;
    font-weight: bold;
}

.inputThing2 {
    height: 60px;
    width: 180px;
    color: #6a6a6a;
    margin: 0px 20px 0px 20px;
    border: 1px solid rgba(0, 0, 0, 0.145);
    border-radius: 10px;
    background-color: #f2f2f2;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.inputThing3 {
    min-height: 46px;
    padding: 10px;
    width: 360px;
    text-align: center;
    color: #333333;
    margin: 0px 20px 0px 20px;
    border: 1px solid rgba(0, 0, 0, 0.145);
    border-radius: 10px;
    background-color: #f2f2f2;
    font-size: 20px;
    font-weight: 600;
}

.inputThing3Half {
    min-height: 46px;
    padding: 10px;
    width: 220px;
    text-align: center;
    color: #333333;
    margin: 0px 0px 0px 20px;
    border: 1px solid rgba(0, 0, 0, 0.145);
    border-radius: 10px;
    background-color: #f2f2f2;
    font-size: 20px;
    font-weight: 600;
}

.inputThing3Quarter {
    min-height: 46px;
    padding: 10px;
    width: 80px;
    text-align: center;
    color: #333333;
    margin: 0px 20px 0px 20px;
    border: 1px solid rgba(0, 0, 0, 0.145);
    border-radius: 10px;
    background-color: #f2f2f2;
    font-size: 20px;
    font-weight: 600;
}


.numbers {
    height: 324px;
    width: 360px;
    margin: 0px 20px 0px 20px;
    border: 2px solid rgba(0, 0, 0, 0.145);
    border-radius: 10px;
}

.number {
    user-select: none;
    cursor: pointer;
    font-size: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
    height: 80px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.047);

}

.number:hover {
    background-color: rgb(225, 225, 225);
}

.imgWrapper {
    width: 80%;
    height: 80%;
}

.cNum {
    background-color: #f90;
    color: white;

}

.cNum:hover {
    transition: 250ms;
    background-color: #466a92;
}

.btn-secondary {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #6498d0;
    border: 2px solid #466a9241;
    font-size: 20px;
    font-weight: bold;
    width: 160px;
}

.btn-secondary:hover {
    transition: 0.2s;
    background-color: #FF9900;
    border-color: #EB8D01;
}

.btn-secondary2:hover {
    transition: 0.2s;
    background-color: #6498d0a4;
    border: 2px solid #466a9241;
}

.btn-secondary-alt {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #FF9900;
    border: 2px solid #EB8D01;
    font-size: 20px;
    font-weight: bold;
    width: 160px;
    color: white
}

.btn-secondary-alt:hover {
    transition: 0.2s;
    background-color: #ff9900b7;
    border: 2px solid #eb8d01ce;
    color: white;
}

.title {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0px;
}

.posData {
    text-wrap: pretty;
    word-break: break-all;
    padding: 0px 20px;
}

.deleteLastNum {
    border: none;
    background: none;
    position: absolute;
    height: 30px;
    font-size: 20px;
    margin-top: -40px;
    margin-left: 130px;
}

.exchangeIcon {
    border: none;
    background: none;
    position: absolute;
    height: 30px;
    font-size: 20px;
    margin-top: 4px;
    margin-left: 298px;
}
</style>
